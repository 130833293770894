<template>
  <v-dialog max-width="700" v-model="ver">
    <v-card>
      <v-card-text v-if="data != null">
        <h3 class="text-center font-weight-medium py-3 info--text">Se han encontrado Hipotecas y Prohibiciones
          asociadas
          al título {{ data.foja }} - {{ data.numero }} - {{ data.anho }}</h3>
        <hr>
        <div class="pt-3" v-if="tituloSeleccionado == null">
          <p class="text-center">No hay información sobre el título indicado</p>

          <div class="text-center">
            <v-btn small color="info" outlined>Continuar</v-btn>
          </div>
        </div>

        <div v-if="tituloSeleccionado != null">
          <div v-if="tituloSeleccionado.hipotecas.length > 0 || tituloSeleccionado.prohibiciones.lenght > 0">
            <div v-if="tituloSeleccionado.hipotecas.length == 0">
              <p class="text-center">El título no posee hipotecas</p>
            </div>
            <div v-if="tituloSeleccionado.hipotecas.length > 0">
              <p class="text-center font-weight-medium pt-3">Solicitar Copia Autorizada de Hipoteca</p>

              <div class="pb-3">
                <v-row :key="'hip-'+index" v-for="(hip, index) in tituloSeleccionado.hipotecas">

                  <v-col cols="12" md="5" class="d-flex align-center pb-3 pb-sm-3  pt-0 ">

                    <p class=" mb-0 pl-2">{{ hip.foja_hipoteca }}{{
                        hip.acompanamiento_foja
                      }} - {{ hip.numero_hipoteca }}{{ hip.acompanamiento }}
                      - {{ hip.anho_hipoteca }}
                      | {{ hip.tipo_hipoteca }}
                    </p>

                  </v-col>
                  <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
                    <v-btn class="px-0" style="min-width: 25px"
                           small
                           elevation="0"
                           height="26"
                           tile
                           outlined
                           color="primary"
                           @click="reducir(hip)"
                    >
                      <v-icon small dark>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                    <input type="text"
                           class="input-spin"
                           disabled
                           v-model="hip.numero_copias"
                    >
                    <v-btn
                        outlined
                        class="px-0" style="min-width: 25px"
                        small
                        elevation="0"
                        height="26"
                        tile
                        color="primary"
                        @click="aumentar(hip)"
                    >
                      <v-icon small dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
                    <div :style="{color: hip.numero_copias > 0 ? 'inherit' : '#6b666694'}">
                      <div v-if="showTotal(hip, 1) > 0"> {{ showTotal(hip, 1) | clp }}</div>
                      <div v-if="showTotal(hip, 1) == 0"> Por cotizar</div>
                    </div>
                  </v-col>

                </v-row>
              </div>
            </div>

            <hr>
            <div v-if="tituloSeleccionado.prohibiciones.length == 0">
              <p class="text-center">El título no posee prohibiciones</p>
            </div>
            <div class="pt-3" v-if="tituloSeleccionado.prohibiciones.length > 0">
              <p class="text-center font-weight-medium pt-3">Solicitar Copia Autorizada de Prohibición</p>

              <div class="pb-3">
                <v-row :key="'prh-'+index" v-for="(prh, index) in tituloSeleccionado.prohibiciones">

                  <v-col cols="12" md="5" class="d-flex align-center pb-3 pb-sm-3 pt-0 ">

                    <p class=" mb-0 pl-2">{{ prh.foja_prohibicion }}{{
                        prh.acompanamiento_foja
                      }} - {{ prh.numero_prohibicion }}{{ prh.acompanamiento }}
                      - {{ prh.anho_prohibicion }}
                      | {{ prh.tipo_prohibicion }}
                    </p>

                  </v-col>
                  <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
                    <v-btn class="px-0" style="min-width: 25px"
                           small
                           elevation="0"
                           height="26"
                           tile
                           outlined
                           color="primary"
                           @click="reducir(prh)"
                    >
                      <v-icon small dark>
                        mdi-minus
                      </v-icon>
                    </v-btn>
                    <input type="text"
                           class="input-spin"
                           disabled
                           v-model="prh.numero_copias"
                    >
                    <v-btn
                        outlined
                        class="px-0" style="min-width: 25px"
                        small
                        elevation="0"
                        height="26"
                        tile
                        color="primary"
                        @click="aumentar(prh)"
                    >
                      <v-icon small dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
                    <div :style="{color: prh.numero_copias > 0 ? 'inherit' : '#6b666694'}">
                      <div v-if="showTotal(prh, 2) > 0"> {{ showTotal(prh, 2) | clp }}</div>
                      <div v-if="showTotal(prh, 2) == 0"> Por cotizar</div>
                    </div>
                  </v-col>

                </v-row>
              </div>

            </div>


          </div>

          <div v-if="tituloSeleccionado.hipotecas.length == 0 && tituloSeleccionado.prohibiciones.lenght == 0">
            <p class="text-center">El título seleccionado no posee hipotecas ni prohibiciones</p>
          </div>


          <div class="text-center pt-3"
               v-if="tituloSeleccionado.hipotecas.length > 0 || tituloSeleccionado.prohibiciones.lenght > 0">
            <v-btn class="mr-1" color="info" outlined small>
              <v-icon small class="pr-1">mdi-close</v-icon>
              Ahora no
            </v-btn>
            <v-btn class="ml-1" color="success" outlined small>
              <v-icon small class="pr-1">mdi-check</v-icon>
              Agregar al carro
            </v-btn>
          </div>


        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import tituloEntity from "@/model/titulo/titulo.entity";

export default {
  name: "ModalSugerenciaCopias",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    foja: {default: ""},
    numero: {default: ""},
    anho: {default: ""},
    data: {
      default: null
    }
  },
  data() {
    return {
      ver: false,
      tituloSeleccionado: null,
      PRECIO_COPIA_HIPOTECA: 3000,
      PRECIO_COPIA_PROHIBICION: 3000
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      } else {
        if (this.data != null) {
          tituloEntity.getHipotecasYGravamenesTitulo(this.data.numero, this.data.anho).then(t => {
            this.tituloSeleccionado = t.obj
            console.log("titulo cargado: ", t)
            if (this.tituloSeleccionado != null) {
              this.tituloSeleccionado.hipotecas.map(h => {
                this.$set(h, "numero_copias", 0)
              })
              this.tituloSeleccionado.prohibiciones.map(p => {
                this.$set(p, "numero_copias", 0)
              })
            }
          })
        }
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    aumentar(item) {
      item.numero_copias++
      console.log(item)
    },
    reducir(item) {
      item.numero_copias--;
    },
    showTotal(item, tipo) {
      if (item.numero_copias > 0) {
        return item.numero_copias * (tipo == 1 ? this.PRECIO_COPIA_HIPOTECA : this.PRECIO_COPIA_PROHIBICION);
      } else {
        return tipo == 1 ? this.PRECIO_COPIA_HIPOTECA : this.PRECIO_COPIA_PROHIBICION;
      }
    }
  }
}
</script>

<style scoped>

</style>
