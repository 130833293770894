<template>

  <div v-if="dataFormulario != null">
    <div
        v-if="tituloSeleccionado != null && (tituloSeleccionado.hipotecas.length > 0 || tituloSeleccionado.prohibiciones.length > 0)">
      <!--
      <p class="py-3 mb-1 text-center" style="color: #2196f3; background: #2196f324; border-radius: 3px">
        Se han encontrado Hipotecas y Prohibiciones
        asociadas
        al título {{ dataFormulario.foja }} - {{ dataFormulario.numero }} - {{ dataFormulario.anho }}
      </p>
      -->
      <div v-if="tituloSeleccionado.hipotecas.length > 0">
        <v-row :key="'hip-'+index" v-for="(hip, index) in tituloSeleccionado.hipotecas">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="5" class="d-flex align-center pr-0  pb-sm-0  pt-0 ">

            <descripcion-item-scbr tipo_certificado="Copia Autorizada de Hipoteca" :foja="hip.foja_hipoteca"
                                   :data-formulario="dataFormulario"
                                   :acompanamiento_foja="hip.acompanamiento_foja"
                                   :numero="hip.numero_hipoteca" :acompanamiento="hip.acompanamiento"
                                   :anho="hip.anho_hipoteca" :tipo="hip.tipo_hipoteca"
            ></descripcion-item-scbr>

          </v-col>
          <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
            <spinner-cantidad :item="hip" @aumentar="hip.numero_copias++"
                              @reducir="hip.numero_copias--"></spinner-cantidad>
          </v-col>
          <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
            <div :style="{color: hip.numero_copias > 0 ? 'inherit' : '#6b666694'}">
              <div v-if="showTotal(hip, 1) > 0"> {{ showTotal(hip, 1) | clp }}</div>
              <div v-if="showTotal(hip, 1) == 0"> Por cotizar</div>
            </div>
          </v-col>

        </v-row>
      </div>

      <div v-if="tituloSeleccionado.prohibiciones.length > 0">
        <v-row :key="'prh-'+index" v-for="(prh, index) in tituloSeleccionado.prohibiciones">

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="5" class="d-flex align-center pr-0  pb-sm-0   pt-0 ">

            <descripcion-item-scbr tipo_certificado="Copia Autorizada Prohibición" :foja="prh.foja_prohibicion"
                                   :data-formulario="dataFormulario"
                                   :acompanamiento_foja="prh.acompanamiento_foja"
                                   :numero="prh.numero_prohibicion" :acompanamiento="prh.acompanamiento"
                                   :anho="prh.anho_prohibicion" :tipo="prh.tipo_prohibicion"
            ></descripcion-item-scbr>
          </v-col>
          <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
            <spinner-cantidad :item="prh" @aumentar="prh.numero_copias++"
                              @reducir="prh.numero_copias--"></spinner-cantidad>
          </v-col>
          <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
            <div :style="{color: prh.numero_copias > 0 ? 'inherit' : '#6b666694'}">
              <div v-if="showTotal(prh, 2) > 0"> {{ showTotal(prh, 2) | clp }}</div>
              <div v-if="showTotal(prh, 2) == 0"> Por cotizar</div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="alzamientos.length > 0">
        <v-row :key="'alz-'+index" v-for="(alz, index) in alzamientos">
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="5" class="d-flex align-center  pb-sm-0 pr-0   pt-0 ">

            <descripcion-item-scbr
                :data-formulario="dataFormulario"
                :tipo_certificado="alz.tipo_certificado"
                :indice="alz.indice"
                tipo="Plazo vencido"
            ></descripcion-item-scbr>
          </v-col>
          <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
            <spinner-cantidad :item="alz" @aumentar="alz.numero_copias++"
                              @reducir="alz.numero_copias--"></spinner-cantidad>
          </v-col>
          <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
            <div :style="{color: alz.numero_copias > 0 ? 'inherit' : '#6b666694'}">
              <div v-if="showTotal(alz, 3) > 0"> {{ showTotal(alz, 3) | clp }}</div>
              <div v-if="showTotal(alz, 3) == 0"> Por cotizar</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

</template>

<script>
import tituloEntity from "@/model/titulo/titulo.entity";
import SpinnerCantidad from "@/components/SpinnerCantidad";
import DescripcionItemScbr from "@/components/DescripcionItemScbr";

/**
 * Este componente despliega los certificados de hipotecas y prohibiciones, para que se carguen, el componente padre
 * debe indicar que es un item con scbr, para que cuando éste componente se mustre, haga una petición al backend
 * para cargar los datos necesarios
 */

export default {
  name: "PanelItemsTitulo",
  components: {DescripcionItemScbr, SpinnerCantidad},
  props: {
    dataFormulario: {
      default: null
    }
  },
  data() {
    return {
      tituloSeleccionado: null,
      PRECIO_COPIA_HIPOTECA: 3000,
      PRECIO_COPIA_PROHIBICION: 3000,
      PRECIO_ALZAMIENTO_SERVIU: 8000,
      alzamientos: []
    }
  },
  mounted() {
    this.getInformacionTitulo()
  },
  computed: {
    getPrecioHipotecas() {
      if (this.dataFormulario == null || this.tituloSeleccionado == null) {
        return 0
      } else {
        let suma = 0;

        this.tituloSeleccionado.hipotecas.map(h => {
          suma += h.numero_copias * h.precio_total
        })

        return suma;
      }
    },
    getPrecioProhibiciones() {
      if (this.dataFormulario == null || this.tituloSeleccionado == null) {
        return 0
      } else {
        let suma = 0;

        this.tituloSeleccionado.prohibiciones.map(p => {
          suma += p.numero_copias * p.precio_total
        })

        return suma;
      }
    },
    getPrecioAlzamientos(){
      if (this.dataFormulario == null || this.tituloSeleccionado == null) {
        return 0
      } else {
        let suma = 0;

        this.alzamientos.map(p => {
          suma += p.numero_copias * p.precio_total
        })

        return suma;
      }
    }
  },

  watch: {
    getPrecioHipotecas(newVal) {
      this.$emit("updatePrecioHipotecas", newVal)
    },
    getPrecioProhibiciones(newVal) {
      this.$emit("updatePrecioProhibiciones", newVal)
    },
    getPrecioAlzamientos(newVal){
      this.$emit("updatePrecioAlzamientos", newVal)
    },
    dataFormulario() {
      this.getInformacionTitulo()
    }
  },
  methods: {
    getInformacionTitulo() {
      tituloEntity.getHipotecasYGravamenesTitulo(this.dataFormulario.numero, this.dataFormulario.anho).then(t => {
        this.alzamientos = [];
        this.tituloSeleccionado = t.obj
        console.log("titulo cargado: ", t);

        if (this.tituloSeleccionado != null) {

          if(this.tituloSeleccionado.hipotecas.length>0 || this.tituloSeleccionado.prohibiciones.length>0){
            this.$emit("mostrar-mensaje-nuevas-funciones")
          }

          this.tituloSeleccionado.hipotecas.map(h => {
            this.$set(h, "numero_copias", 0)
          })
          this.tituloSeleccionado.prohibiciones.map(p => {
            this.$set(p, "numero_copias", 0)
            if (p.plazo_vencido) {
              let alz = {
                numero_copias: 0,
                indice: `${p.foja_prohibicion}${p.acompanamiento_foja}-${p.numero_prohibicion}${p.acompanamiento}-${p.anho_prohibicion}`,
                tipo_certificado: "Alzamiento de Prohibicion SERVIU",
                precio_total: this.PRECIO_ALZAMIENTO_SERVIU
              }
              this.alzamientos.push(alz)
            }
          })
        }
      })
    },
    getInformacionHipotecas() {
      if (this.tituloSeleccionado != null) {
        return this.tituloSeleccionado.hipotecas
      }

      return [];
    },

    getInformacionProhibiciones() {
      if (this.tituloSeleccionado != null) {
        return this.tituloSeleccionado.prohibiciones
      }
      return [];
    },

    getInformacionAlzamientos() {
      return this.alzamientos
    },

    showTotal(item, tipo) {
      switch (tipo) {
        case 1:
          if (item.numero_copias > 0) {
            return item.numero_copias * item.precio_total;
          } else {
            return item.precio_total;
          }
        case 2:
          if (item.numero_copias > 0) {
            return item.numero_copias * item.precio_total;
          } else {
            return item.precio_total;
          }
        case 3:
          if (item.numero_copias > 0) {
            return item.numero_copias * item.precio_total;
          } else {
            return item.precio_total;
          }

        default:
          return 0;
      }

    }
  }


}
</script>

<style scoped>

</style>
