<template>
  <div>
    <TheBreadcrumb in-certificados/>
    <v-row>
      <v-col cols="12" md="8" class="pr-md-0">

        <v-scroll-y-reverse-transition mode="out-in">
          <v-card
              key="seleccion"
              v-if="!jurisdiccionSeleccionada"
              class="mb-6"
          >
            <v-card-text>
              <h2 class="font-weight-regular mb-3">{{ mensajes.titulo_jurisdiccion }}</h2>
              <v-divider></v-divider>
              <div class="d-flex px-0" style=" font-size: 15px !important; cursor: pointer"
                   @click="seleccionarJurisdiccion()">
                <v-icon style="font-size: inherit" class="pr-2" color="success">
                  mdi-checkbox-blank-outline
                </v-icon>
                <p class="my-3 font-weight-medium pl-1" style="justify-content: left !important; ">
                  <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
              <div class="d-flex px-0" style="font-size: 15px; cursor: pointer"
                   @click="jurisdiccion.dialog = true">
                <v-icon color="info" small class="pr-2" style="font-size: inherit">
                  mdi-checkbox-blank-outline
                </v-icon>
                <p class="my-0 font-weight-regular pl-1" style="justify-content: left !important;">
                  <!--               <span> La propiedad se encuentra en otra comuna</span>-->
                  <span>{{ mensajes.otra_jurisdiccion }}</span>
                </p>
              </div>


            </v-card-text>
          </v-card>

          <v-card
              key="confirmado"
              v-else
              class="mb-6"
          >
            <v-card-text class="py-0">
              <div class="d-flex px-4" style="font-size: 15px !important; cursor: pointer">
                <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-check</v-icon>
                <p class="my-3 success--text pl-1" style="justify-content: left !important; ">

                  <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-scroll-y-reverse-transition>

        <v-fade-transition>
          <v-card class="px-8 py-2" v-if="!load.certificado" id="copiasYCertificado">
            <v-row>
              <v-col cols="12" class="pb-0">
                <h2 class="font-weight-regular d-flex">
                  <div>
                    {{ url_tipo_registro | notUrl }}
                  </div>
                </h2>
              </v-col>

              <!--Formulario FNA -->
              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.COPIAS_Y_CERTIFICADOS || certificado.id_grupo_sugerencias === ID_GRUPO.PRH_PROPIEDAD || certificado.id_grupo_sugerencias === ID_GRUPO.HIP_PROPIEDAD">
                <div v-if="mostrarBuscarSociedad === false">
                  <h3 class="text-subtitle-1 mb-0 font-weight-regular" v-if="!buscarPorNombre">
                    Ingrese los datos de un título de dominio
                  </h3>
                  <v-row>
                    <v-col class="pt-0">
                      <!--                    :key="habilitarFormulario"-->
                      <formulario-fna
                          class="pt-0"
                          :key="keyFormularioFna"
                          @send="validarRef()"
                          :id_tipo_registro="certificado.id_tipo_registro_FK"
                          :campos-habilitados="habilitarFormulario"
                          :certificado="certificado"
                          @setFormulario="puedeValidar($event)"
                          @setBuscarFormulario="cargarYValidar($event)"
                          :item="itemsFNA"
                          @buscandoNombre="buscarPorNombre=$event"
                          :keyIncrementa="nuevaKey"></formulario-fna>

                    </v-col>
                    <v-col class="pt-md-3" cols="12" md="3" v-if="!buscarPorNombre">
                      <validador-indices
                          ref="validador"
                          :puede-validar-indice="puedeValidarIndice"
                          :habilitar-boton="habilitarFormulario"
                          :certificado="certificado"
                          :data-formulario="dataFormulario"
                          @corregirFoja="corregirFojaTitulo($event)"
                          @confirmarIndice="confirmarIndice"
                          @contieneConstitucion="tieneConstitucion = $event"
                          @naturaleza="naturaleza = $event"
                          @escritura="escritura = $event"
                          @noMostrarDocumentos="mostrarDocumentosTentativos2 = $event"
                          @cambiaFoja="dataFormulario.foja = $event "
                      />

                      <div class="text-right pr-1">
                        <v-btn text x-small color="info"
                               :disabled="!mostrarDocumentosTentativos"
                               @click="limpiarFormularioFna">Limpiar formulario
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <div v-if="certificado.id_tipo_registro_FK === 7 && habilitarFormulario === true"
                       style="margin-top: -30px">
                    <small class="ma-0 pa-0">¿No sabe su foja, número y año? Presione
                      <span
                          @click="mostrarBuscarSociedad = true"
                          style="cursor: pointer"
                          class="blue--text">aquí</span>
                    </small>
                  </div>
                </div>
                <div v-else>
                  <tab-nombre-sociedad
                      @seleccionarItem="recibirDatosParaBuscar($event)"
                  ></tab-nombre-sociedad>

                  <small class="ma-0 pa-0">
                                        <span
                                            @click="mostrarBuscarSociedad = false"
                                            style="cursor: pointer"
                                            class="blue--text">
                                            regresar a busqueda por foja, numero y año ↩
                                        </span>
                  </small>
                </div>
              </v-col>

              <!--Formulario Planos -->
              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.PLANOS_LEY || certificado.id_grupo_sugerencias === ID_GRUPO.PLANOS_PROPIEDAD ">
                <formulario-planos :key="jurisdiccionSeleccionada"
                                   :campos-habilitados="jurisdiccionSeleccionada"
                                   @setFormulario="setFormulario($event)"></formulario-planos>
              </v-col>

              <!--Formulario Alzamiento -->

              <v-col cols="12"
                     v-if="certificado.id_grupo_sugerencias === ID_GRUPO.ALZAMIENTO_Y_CANCELACION">

                <h3 class="text-subtitle-1 mb-0 font-weight-regular"> Ingrese los datos de escritura
                  firmada
                  electrónicamente</h3>
                <div v-if="formularioAlzamientoPrecargado">
                  <alzamiento-precargado :data="dataFormulario"></alzamiento-precargado>
                </div>
                <div v-if="!formularioAlzamientoPrecargado">
                  <alzamiento-cancelacion :campos-habilitados="jurisdiccionSeleccionada"
                                          @setFormulario="setFormulario($event)"></alzamiento-cancelacion>
                </div>
              </v-col>

              <!--Formulario Alzamiento -->

              <!-- Formulario Escrituras Públicas -->
              <v-col cols="12" v-if="certificado.id_grupo_sugerencias === ID_GRUPO.ESCRITURAS_PUBLICAS">
                <div v-if="!formularioEscrituraPublicaPrecargado">
                  <formulario-escritura-publica
                      @MostrarElemento="mostrarDocumentosTentativos = $event"
                      @e-cambiar-valor-certificado="cambiarValorCertificado($event)"
                      :campos-habilitados="jurisdiccionSeleccionada"
                      @notarioNoEstaEnLista="notarioNoEstaEnLista = $event"
                      @setFormulario="setFormulario($event)">
                  </formulario-escritura-publica>
                </div>
                <div v-if="formularioEscrituraPublicaPrecargado">
                  <formulario-escritura-publica-precargado
                      :escritura="dataFormulario"
                      @setFormulario="setFormulario($event)"></formulario-escritura-publica-precargado>
                </div>

              </v-col>
              <!-- Formulario Escrituras Públicas -->
            </v-row>
            <v-scroll-y-transition v-if="notarioNoEstaEnLista || mostrarDocumentosTentativos">
              <!--      ------------------------------------------------------------------------------------ replica de codigo ----------------------------------------------------------------------      -->
              <div v-show="mostrarLista && mostrarDocumentosTentativos2">
                <div
                    v-if="url_tipo_certificado === 'copia-con-vigencia' && tieneConstitucion === true && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <!--Documento Seleccionado -->
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">

                      <documentos-tentativos
                          :escritura="escritura"
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                    v-else-if="url_tipo_certificado === 'copia-con-vigencia' && tieneConstitucion === false && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <div v-if="mostrarDocumentosTentativos">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-alert
                        type="info"
                        outlined
                        border="left"
                        :icon="false"
                        class="text-center ">
                      <p class="mb-0 font-md info--text">
                        Para el título {{
                          dataFormulario.foja + "-" + dataFormulario.numero + "-" + dataFormulario.anho
                        }} correspondiente a una <span style="font-weight: bold">{{ naturaleza }}</span> no es posible
                        solicitar el trámite {{ url_tipo_certificado | notUrl }}
                      </p>
                    </v-alert>
                  </div>
                </div>
                <!--       ------------------------------------------------CERT.VIGENCIA SOCIEDAD------------------------  -->
                <div
                    v-else-if="url_tipo_certificado === 'cert-vigencia-sociedad' && tieneConstitucion === true && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">

                      <documentos-tentativos
                          :escritura="escritura"
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">
                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                    v-else-if="url_tipo_certificado === 'cert-vigencia-sociedad' && tieneConstitucion === false && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <div v-if="mostrarDocumentosTentativos">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-alert
                        type="info"
                        outlined
                        border="left"
                        :icon="false"
                        class="text-center ">
                      <p class="mb-0 font-md info--text">
                        Para el título {{
                          dataFormulario.foja + "-" + dataFormulario.numero + "-" + dataFormulario.anho
                        }} correspondiente a una <span style="font-weight: bold">{{ naturaleza }}</span> no es posible
                        solicitar el trámite {{ url_tipo_certificado | notUrl }}
                      </p>
                    </v-alert>
                  </div>
                </div>
                <!-- -----------------------------------CERT. REPRESENTANTE LEGAL---------------------------------  -->
                <div
                    v-else-if="url_tipo_certificado === 'cert-representante-legal' && tieneConstitucion === true && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">

                      <documentos-tentativos
                          :escritura="escritura"
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                    v-else-if="url_tipo_certificado === 'cert-representante-legal' && tieneConstitucion === false && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <div v-if="mostrarDocumentosTentativos">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-alert
                        type="info"
                        outlined
                        border="left"
                        :icon="false"
                        class="text-center ">
                      <p class="mb-0 font-md info--text">
                        Para el título {{
                          dataFormulario.foja + "-" + dataFormulario.numero + "-" + dataFormulario.anho
                        }} correspondiente a una <span style="font-weight: bold">{{ naturaleza }}</span> no es posible
                        solicitar el trámite {{ url_tipo_certificado | notUrl }}
                      </p>
                    </v-alert>
                  </div>
                </div>
                <!--  --------------------------------------CERT. CAPITAL SOCIEDAD----------------------------------------- -->
                <div
                    v-else-if="url_tipo_certificado === 'cert-capital-sociedad' && tieneConstitucion === true && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">

                      <documentos-tentativos
                          :escritura="escritura"
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                    v-else-if="url_tipo_certificado === 'cert-capital-sociedad' && tieneConstitucion === false && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <div v-if="mostrarDocumentosTentativos">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-alert
                        type="info"
                        outlined
                        border="left"
                        :icon="false"
                        class="text-center ">
                      <p class="mb-0 font-md info--text">
                        Para el título {{
                          dataFormulario.foja + "-" + dataFormulario.numero + "-" + dataFormulario.anho
                        }} correspondiente a una <span style="font-weight: bold">{{ naturaleza }}</span> no es posible
                        solicitar el trámite {{ url_tipo_certificado | notUrl }}
                      </p>
                    </v-alert>
                  </div>
                </div>
                <!--  --------------------------------------------COPIA AUTORIZADA------------------------------------  -->
                <div
                    v-else-if="url_tipo_certificado === 'copia-autorizada' && tieneConstitucion === true && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">

                      <documentos-tentativos
                          :escritura="escritura"
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"></documentos-tentativos>
                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div
                    v-else-if="url_tipo_certificado === 'copia-autorizada' && tieneConstitucion === false && url_tipo_registro === 'copias-y-certificados-comercio'">
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <v-col cols="12">
                      <documentos-tentativos
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      />

                    </v-col>
                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar
                          al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!--  --------------------------------------CASOS NORMALES-----------------------------------------------------------------  -->
                <div v-else>
                  <v-row
                      v-if="mostrarDocumentosTentativos && !buscarPorNombre && !formularioEscrituraPublicaPrecargado && !documentosAgregados">
                    <p class="d-none">{{ mostrarLista = true }}</p>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" v-if="nombreComunidad!= '' && mostrarMensajeComunidad==true">
                      <v-alert outlined color="secondary" class="text-center">
                        Comunidad : {{ nombreComunidad }}
                        <div>Comunero : {{ nombreComunero }}</div>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">

                      <documentos-tentativos
                          :key="keyCertificado"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :show="certificado"
                          :listaCertificados="sugerencias"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                          @tieneCertificacionVigencia="tieneCertificacionVigencia($event)"
                      />

                    </v-col>
                    <!--Documento Seleccionado -->
                    <v-col cols="12" class="pb-0" v-if="sugerencias.length > 1">
                      <p class="py-3 mb-1 text-center"
                         style="color: #2196f3; background: #2196f324; border-radius: 3px">
                        ¿Desea
                        otros documentos para la inscripción citada?</p>
                    </v-col>

                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0 pb-0">
                      <documentos-tentativos
                          :escritura="escritura"
                          :hide="certificado"
                          :listaCertificados="sugerencias"
                          :mostrarMensajeComunidad="mostrarMensajeComunidad"
                          :numero-carillas="carillas_encontradas"
                          :precio-carillas="precio_adicional_carillas"
                          @aumentar="aumentarCantidadDocumentos($event)"
                          @reducir="reducirCantidadDocumentos($event)"
                      ></documentos-tentativos>
                    </v-col>
                    <!--Otros Documentos -->
                    <v-col cols="12" class="pt-0" v-if="isItemConScbr && !habilitarFormulario">
                      <panel-items-titulo
                          ref="panelItemsTitulo"
                          :data-formulario="dataFormulario"
                          @mostrar-mensaje-nuevas-funciones="verModalIngresoComunero ? verModalNuevasFunciones = false :verModalNuevasFunciones = true"
                          @updatePrecioHipotecas="setPrecioCopiasHipotecas"
                          @updatePrecioProhibiciones="setPrecioCopiasProhibiciones"
                          @updatePrecioAlzamientos="setPrecioAlzamientos"
                      ></panel-items-titulo>
                    </v-col>

                    <!-- Agregar al modal-pago -->
                    <v-col cols="12" class="d-flex  flex-column pt-5">

                      <div class="d-flex justify-end mb-3 pt-0"><span
                          class="font-weight-bold pr-2 ">Subtotal: </span>
                        <span v-if="getSubtotal > 0">{{ getSubtotal | clp }}</span>
                        <span v-if="getSubtotal === 0">Por cotizar</span>
                      </div>
                      <div class="d-flex justify-end">

                        <v-btn :disabled="dataFormulario== null " color="primary"
                               @click="validarSerie">
                          Agregar al carro
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-scroll-y-transition>
            <!--        -------------------------------------------------------- FIN replica de codigo ----------------------------------------------------------                -->
            <!-- Agregar al modal-pago -->

          </v-card>
        </v-fade-transition>
      </v-col>
      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>

    <!--    <modal-serie :ver-dialog-serie="dialogSerie" :serie="serie" @cerrarModalSerie="cerrarModalSerie"-->
    <!--                 @recargarBusqueda="recargarModalSerie()"-->
    <!--                 @agregarDocumentos="corregirFoja($event)"></modal-serie>-->
    <!--    <modal-jurisdiccion :verDialog="jurisdiccion.dialog"-->
    <!--                        @ocultarDialogJurisdiccion="ocultarDialogJurisdiccion"></modal-jurisdiccion>-->
    <simple-modal :verDialog="jurisdiccion.dialog" @ocultarDialog="jurisdiccion.dialog = false">
      <div>
        <h3 class="text-center pb-3">Otras comunas</h3>
        <v-divider></v-divider>
        <p class="my-3 px-4 text-center">
          Si la comuna no es parte de la jurisdicción del Conservador de Bienes Raíces de La Serena debe revisar el
          portal de
          la <a
            href="http://www.conservadores.cl/oficios_registrales/" target="_blank">Corporación Chilena de
          Estudios de
          Derecho Registral</a> para realizar su trámite.
        </p>
        <v-btn outlined block color="info" @click="jurisdiccion.dialog = false">Volver</v-btn>
      </div>
    </simple-modal>
    <modal-documento-agregado :verDialog="modalAgregar.verModalDocumentoAgregado"
                              :documentosAgregados="modalAgregar.lista_agregados"
                              :documentosRepetidos="modalAgregar.documentos_repetidos"
                              :documentosEnLimite="modalAgregar.limite_carro"
                              @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"></modal-documento-agregado>

    <simple-modal-static :verDialog="verModalIncompatibilidadAlzamiento">
      <v-row>
        <v-col class="text-center" cols="12">Documentos incompatibles en el carro, puede quitar los alzamientos
          y
          cancelaciones para realizar la operación solicitada
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn
              color="warning"
              outlined
              @click="()=>{this.$router.push('/tramites')}"
          >Volver a Trámites
          </v-btn>
        </v-col>
      </v-row>
    </simple-modal-static>

    <modal-agregar-vigencia :certificado="certificadoModalAgregarVigencia" :verDialog="verModalAgregarVigencia"
                            @ocultarDialog="aumentarCantidadDocumentosVigencia(certificadoModalAgregarVigencia, $event)"></modal-agregar-vigencia>
    <modal-ingreso-nombre-comunero :verDialog="verModalIngresoComunero"
                                   @e-nombreComunero="nombreComunero = $event.nombre; numeroComunero = $event.numero"
                                   @ocultarDialog="verModalIngresoComunero = false"></modal-ingreso-nombre-comunero>


    <simple-modal :verDialog="verModalGP" @ocultarDialog="verModalGP = false">
      <div><h3 class="font-weight-regular info--text text-center">Certificado de Hipotecas y Gravámenes</h3></div>
      <v-row>
        <v-col cols="3" class="d-flex justify-center align-center">
          <v-icon color="info" x-large>mdi-information-outline</v-icon>
        </v-col>
        <v-col cols="9" class="text-center">Los alzamientos y/o cancelaciones deben pertenecer a una sola
          propiedad
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn @click="verModalGP = false" color="info" outlined block>Entiendo</v-btn>
        </v-col>
      </v-row>
    </simple-modal>

    <simple-modal :verDialog="verModalAgregarAlzamiento" @ocultarDialog="verModalAgregarAlzamiento = false">
      <div>
        <h3 class="font-weight-regular warning--text text-center">Atención</h3>
        <v-row>
          <v-col cols="12" md="3" class="d-flex justify-center align-center">
            <v-icon color="warning" x-large>mdi-alert-circle</v-icon>
          </v-col>
          <v-col cols="12" md="9" class="text-justify">Al agregar los ítems especificados al carro de compras
            usted
            acepta que
            la
            escritura ingresada es únicamente de alzamiento(s) y/o cancelación(es).
          </v-col>
          <v-col cols="12" md="6" class="text-center pl-md-7">
            <v-btn outlined block color="success" @click="agregarAlCarro(sugerencias, dataFormulario)">
              Acepto
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="text-center pr-md-7 pt-2 pt-md-3">
            <v-btn outlined block color="info" @click="verModalAgregarAlzamiento = false">Cancelar</v-btn>
          </v-col>
        </v-row>
      </div>
    </simple-modal>

    <simple-modal tipo="info" :ver-dialog="dialogSeriePlano" @ocultarDialog="dialogSeriePlano = false">
      <div>
        <h3 class="font-weight-regular info--text text-center pb-2">Plano no encontrado</h3>
        <v-divider></v-divider>
        <p class="text-center py-4">
          El plano ingresado no puede ser solicitado desde nuestro portal, por favor escríbanos a
          cotizaciones@cbrls.cl
          para concretar su solicitud.
        </p>
        <div class="text-center">
          <v-btn color="info" outlined @click="dialogSeriePlano = false">Aceptar</v-btn>
        </div>
      </div>
    </simple-modal>
    <simple-modal tipo="info" :ver-dialog="verModalNoPuedeAgregarAlCarro" >
      <div>
        <h3 class="font-weight-regular info--text text-center pb-2">Restricciones de carro de compras</h3>
        <v-divider></v-divider>
        <p class="text-center py-4">
         Actualmente ya posee una solicitud de Copia de escritura en su carro, por favor finalice su compra antes de agregar este trámite.
        </p>
        <div class="text-center">
          <v-btn color="info" outlined @click="verModalNoPuedeAgregarAlCarro = false">Aceptar</v-btn>
        </div>
      </div>
    </simple-modal>
    <modal-confirma-escritura-publica :ver-dialog="modalConfirmaEscrituraPublica"
                                      @ocultarDialog="modalConfirmaEscrituraPublica = false"
                                      @confirmarEscrituraPublica="confirmarEscrituraPublica"></modal-confirma-escritura-publica>
    <modal-sugerencia-copias :data="dataFormulario" :ver-dialog="verModalSugerenciaCopias"
                             @ocultarDialog="verModalSugerenciaCopias = false"
    ></modal-sugerencia-copias>
    <modal-mensaje-nuevas-funciones
        :titulo="'Nuevas funcionalidades en nuestro Portal Web'"
        :mensaje="'Solicite fácilmente sus Copias Autorizadas de Hipotecas y Prohibiciones vigentes para el título citado'"
        :ver-dialog="verModalNuevasFunciones"
        @ocultarDialog="verModalNuevasFunciones = false"
    ></modal-mensaje-nuevas-funciones>
    <modal-confirmar-datos-adicionales-escritura-publica
        :item-datos-adicionales="itemDatosAdicionalesParaModalConfirmar"
        :ver-dialog="verModalConfirmarDatosAdicionalesEscrituraPublica"
        @confirmarItem="aumentarCantidad($event)"
        @e-ocultar-dialog="verModalConfirmarDatosAdicionalesEscrituraPublica = false"
    />
  </div>
</template>

<script>
import registros, {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import AlzamientoCancelacion
  from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/AlzamientoCancelacion";
import FormularioFna from "@/components/SolicitudDocumentos/Formularios/FormularioFna";
import DocumentosTentativos from "@/components/SolicitudDocumentos/DocumentosTentativos";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
// import ModalSerie from "@/components/SolicitudDocumentos/modals/ModalSerie";
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import carro from "@/model/carro/carro.entity";
// import ModalJurisdiccion from "@/components/SolicitudDocumentos/modals/ModalJurisdiccion";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import AlzamientoPrecargado
  from "@/components/SolicitudDocumentos/Formularios/AlzamientoCancelacion/AlzamientoPrecargado";
import SimpleModalStatic from "@/components/SimpleModalStatic";
import ModalAgregarVigencia from "@/components/ModalAgregarVigencia";
import FormularioPlanos from "@/components/SolicitudDocumentos/Formularios/FormularioPlanos";
import SimpleModal from "@/components/SimpleModal";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import FormularioEscrituraPublica from "@/components/SolicitudDocumentos/Formularios/FormularioEscrituraPublica";
import FormularioEscrituraPublicaPrecargado
  from "@/components/SolicitudDocumentos/Formularios/FormularioEscrituraPublicaPrecargado";
import ValidadorIndices from "@/components/Tramites/ValidadorIndices";
import ModalConfirmaEscrituraPublica from "@/components/Tramites/ModalConfirmaEscrituraPublica";
import ModalSugerenciaCopias from "@/components/Tramites/ModalSugerenciaCopias";
import PanelItemsTitulo from "@/views/index/tramites-en-linea/PanelItemsTitulo";
import ModalMensajeNuevasFunciones from "@/components/Tramites/ModalMensajeNuevasFunciones";
import ModalIngresoNombreComunero from "@/components/ModalIngresoNombreComunero.vue";
import TabNombreSociedad from "@/views/index/indices/TabNombreSociedad.vue";
import ModalConfirmarDatosAdicionalesEscrituraPublica
  from "@/components/Tramites/ModalConfirmarDatosAdicionalesEscrituraPublica.vue";
import {escrituraEntity} from "@/model/escritura/escritura.entity";
import store from "@/store";

export default {
  name: "TramitesEnLineaViewMenuCertificado",
  components: {
    ModalConfirmarDatosAdicionalesEscrituraPublica,
    TabNombreSociedad,
    ModalIngresoNombreComunero,
    ModalMensajeNuevasFunciones,
    PanelItemsTitulo,
    ModalSugerenciaCopias,
    ModalConfirmaEscrituraPublica,
    ValidadorIndices,
    FormularioEscrituraPublicaPrecargado,
    FormularioEscrituraPublica,
    SimpleModal,
    FormularioPlanos,
    SimpleModalStatic,
    AlzamientoPrecargado,
    // ModalJurisdiccion,
    AlzamientoCancelacion,
    InformacionDocumento,
    FormularioFna,
    DocumentosTentativos,
    // ModalSerie,
    TheBreadcrumb,
    ModalDocumentoAgregado,
    ModalAgregarVigencia
  },
  data() {
    return {
      notarioNoEstaEnLista: false,
      verModalNuevasFunciones: false,
      ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
      carillas_encontradas: 0,
      precio_adicional_carillas: 0,
      puedeValidarIndice: false,
      keyFormularioFna: 0,
      habilitarFormulario: false,
      ID_GRUPO: registros.ID_GRUPO,
      url_tipo_certificado: "",
      url_tipo_registro: "",
      certificado: {},
      sugerencias: {},
      dataFormulario: null,
      load: {
        certificado: false
      },
      dialogSerie: false,
      dialogSeriePlano: false,
      serie: null,
      mostrarDocumentosTentativos: false,
      formularioAlzamientoPrecargado: false,
      jurisdiccion: {
        switch: false,
        remarcado: false,
        dialog: false
      },
      mostrarMensajeComunidad: false,
      verModalIngresoComunero: false,
      nombreComunero: "",
      numeroComunero: null,
      verModalAgregarVigencia: false,
      certificadoModalAgregarVigencia: null,
      verModalDocumentosRepetidos: false,
      verModalIncompatibilidadAlzamiento: false,
      verModalGP: false,
      verModalAgregarAlzamiento: false,
      verModalSugerenciaCopias: false,
      documentosRepetidosResponse: [],
      modalAgregar: {
        documentos_repetidos: [],
        limite_carro: [],
        lista_agregados: [],
        verModalDocumentoAgregado: false
      },
      jurisdiccionSeleccionada: false,
      buscarPorNombre: false,
      mensajes: {
        jurisdiccion_ok: "LA CUESTION SE ENCUENTRA UBICADA EN LA COMUNA DE LA SERENA O LA HIGUERA",
        otra_jurisdiccion: "LA CUESTION ESTA EN OTRO LUGAR",
        titulo_jurisdiccion: "Seleccione donde se encuentra ubicada la propiedad"
      },
      formularioEscrituraPublicaPrecargado: false,
      modalConfirmaEscrituraPublica: false,
      certificadosConScbr: [
        ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE,
        ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA,
        ID_TIPO_CERTIFICADO.LITIGIOS,
        ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES,
        ID_TIPO_CERTIFICADO.CERT_BIEN_FAMILIAR
      ],
      precio_copias_hipotecas: 0,
      precio_copias_prohibiciones: 0,
      precio_alzamientos: 0,
      certificadoHipoteca: {},
      certificadoProhibiciones: {},
      alzamientoServiu: {},
      documentosAgregados: false,
      nombreComunidad: "",
      escritura: null,
      cerDomVigente: null,
      keyCertificado: 0,

      //------------------------------------------
      mostrarBuscarSociedad: false,
      itemsFNA: {numero: -1},
      tieneConstitucion: null,
      mostrarLista: false,
      mostrarDocumentosTentativos2: true,
      nuevaKey: null,
      naturaleza: null,
      certificacionDeVigencia: false,
      verModalConfirmarDatosAdicionalesEscrituraPublica: false,
      itemDatosAdicionalesParaModalConfirmar: null,
      mostrarEscrituraPublica: true,
      verModalNoPuedeAgregarAlCarro: false
    }
  },
  watch: {
    mostrarMensajeComunidad() {
      if (this.mostrarMensajeComunidad) {
        console.log('certificado mod', this.certificado)
        this.certificado.precio = 2300
        this.certificado.tipo_certificado = 'Dominio Vigente'
        //this.keyCertificado = this.keyCertificado+1;


        this.sugerencias.map(sug => {
          console.log('intentando modificar sugerencias')
          if (sug.id_tipo_certificado == 20000) {
            sug.precio = 2300
            sug.tipo_certificado = 'Dominio Vigente'
          }
        })
        console.log(this.sugerencias)
      }
    }
  },
  methods: {
    tieneCertificacionVigencia(evento) {
      this.certificacionDeVigencia = evento
    },
    cambiarValorCertificado(nuevoValorCertificado) {
      console.log(nuevoValorCertificado)
      this.sugerencias[0].precio = nuevoValorCertificado
    },
    recibirDatosParaBuscar(items) {
      console.log(items);
      this.mostrarBuscarSociedad = false;
      this.itemsFNA = items
    },

    corregirFoja(datosIndice) {
      this.carillas_encontradas = 0;
      this.precio_adicional_carillas = 0;


      if (datosIndice != null) {
        this.dataFormulario.foja = datosIndice.foja;
        this.carillas_encontradas = datosIndice.numero_carillas;
        //TODO corregir precio carrillas
        // this.precio_adicional_carillas = datosIndice.precio_carillas
        this.precio_adicional_carillas = 0;
      }
      this.confirmarIndice()
    },
    corregirFojaTitulo(item) {
      console.log("item enviado: ", item);

      if (item.certificados == null) {
        //si no trae sugerencias, llamar a corregirFoja normal
        this.corregirFoja(item);
        return;
      }
      console.log('certificados corregir foja titulo', item)
      this.sugerencias = item.certificados;

      if(this.escritura && Number.parseInt(this.escritura.anho_maximo_registro_notaria) > this.escritura.anho && this.escritura.comuna === 'LA SERENA') {
        this.sugerencias.unshift(JSON.parse(JSON.stringify(this.escritura)))
        this.sugerencias[0].id_tipo_registro_FK = 1
      }

      this.nombreComunidad = item.nombre_comunidad
      this.sugerencias.map(sug => {
        this.$set(sug, "numero_copias", sug.id_tipo_certificado == this.certificado.id_tipo_certificado ? 1 : 0);
        this.$set(sug, "vigencia", -1);
      })

      if (this.certificado.has_vigencia) {
        this.certificadoModalAgregarVigencia = this.sugerencias.filter(doc => {
          return doc.id_tipo_certificado == this.certificado.id_tipo_certificado
        })[0]
        this.verModalAgregarVigencia = true;
      }
      if (item.nombre_comunidad.length > 0 && this.certificado.id_tipo_certificado === registros.ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE) {
        this.mostrarMensajeComunidad = true
        this.verModalIngresoComunero = true;
      }
      this.corregirFoja(item);
    },
    setPrecioCopiasHipotecas(precio) {
      console.log("cambiando precio", precio)
      this.precio_copias_hipotecas = precio;
    },
    setPrecioCopiasProhibiciones(precio) {
      console.log("cambiando precio", precio)
      this.precio_copias_prohibiciones = precio
    },
    setPrecioAlzamientos(precio) {
      console.log("cambiando precio", precio)
      this.precio_alzamientos = precio;
    },
    confirmarEscrituraPublica() {
      this.modalConfirmaEscrituraPublica = false;
      this.agregarAlCarro();
    },

    limpiarFormularioFna() {
      this.nombreComunidad = ''
      this.nombreComunero = ''
      this.numeroComunero = null
      this.keyFormularioFna++;
      this.puedeValidarIndice = false;
      this.habilitarFormulario = true;
      this.carillas_encontradas = 0;
      this.precio_adicional_carillas = 0;
      this.mostrarDocumentosTentativos = false;
      this.itemsFNA = {numero: -1};
      this.tieneConstitucion = null;
      this.mostrarLista = false;
    },
    validarRef() {
      this.$refs.validador.validarIndice();
    },
    confirmarIndice() {
      this.habilitarFormulario = false;
      this.mostrarDocumentosTentativos = true;
    },
    desactivarBuscarNombre() {
      this.buscarPorNombre = false;
    },
    seleccionarJurisdiccion() {
      setTimeout(() => {
        this.jurisdiccionSeleccionada = true
        this.habilitarFormulario = true;

        if (this.formularioAlzamientoPrecargado) {
          this.mostrarDocumentosTentativos = true;
        }
      }, 200)
    },
    ocultarDialogJurisdiccion() {
      this.jurisdiccion.dialog = false;
    },

    /**
     * En este metodo se construye el objeto que hay que mandar al backend para que se pueda guardar el carro, en caso
     * de que no exista sesión, solo se guardara en Vuex.
     *
     * Los arreglos de hipotecas, prohibiciones y alzamientos se usan en certificados específicos
     */
    agregarAlCarro() {

      if(this.escritura && Number.parseInt(this.escritura.anho_maximo_registro_notaria) > this.escritura.anho && this.escritura.comuna === 'LA SERENA'){
        this.sugerencias[0].id_tipo_registro_FK = this.escritura.id_tipo_registro_FK
      }

      let sug = JSON.parse(JSON.stringify(this.sugerencias));
      let items_alz = [];

      if (this.isItemConScbr) {

        let hipotecas = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionHipotecas()));
        console.log("hipoteca",hipotecas)
        let prohibiciones = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionProhibiciones()));
        console.log("prohibicion", prohibiciones)
        let alzamientos = JSON.parse(JSON.stringify(this.$refs.panelItemsTitulo.getInformacionAlzamientos()));
        console.log("alzamiento", alzamientos)

        hipotecas.map(h => {

          if (h.numero_copias > 0) {
            let copiaCertificadoHipoteca = {
              id_tipo_certificado:this.certificadoHipoteca.id_tipo_certificado,
              tipo_certificado:this.certificadoHipoteca.tipo_certificado,
              precio:this.certificadoHipoteca.precio,
              precio_recalculado:this.certificadoHipoteca.precio_recalculado,
              dias_entrega:this.certificadoHipoteca.dias_entrega,
              id_tipo_registro_FK:this.certificadoHipoteca.id_tipo_registro_FK,
              tipo_registro:this.certificadoHipoteca.tipo_registro,
              solo_valija_electronica:this.certificadoHipoteca.solo_valija_electronica,
              id_grupo_sugerencias:this.certificadoHipoteca.id_grupo_sugerencias,
              grupo_sugerencias:this.certificadoHipoteca.grupo_sugerencias,
              id_area_agrupacion:this.certificadoHipoteca.id_area_agrupacion,
              area_agrupacion:this.certificadoHipoteca.area_agrupacion,
              solo_anexo:this.certificadoHipoteca.solo_anexo,
              has_vigencia:this.certificadoHipoteca.has_vigencia,
              tipo_formulario:this.certificadoHipoteca.tipo_formulario,
              numero_copias: null,
              vigencia: -1,
              notas:this.certificadoHipoteca.notas,
              precio_carillas:0,
              id_tipo_tramite:1,
              id_tipo_certificado_FK:this.certificadoHipoteca.id_tipo_certificado,
              indice: null
            }
            copiaCertificadoHipoteca.precio = h.precio_total
            sug.push({
              ...copiaCertificadoHipoteca,
              indice: h.foja_hipoteca + h.acompanamiento_foja + "-" + h.numero_hipoteca + h.acompanamiento + "-" + h.anho_hipoteca,
              numero_copias: h.numero_copias
            })
          }
        })
        /*if (this.escritura != null && this.escritura.numero_copias > 0) {
          let copiaEscritura = {
            area_agrupacion: "Escrituras públicas",
            dias_entrega: 5,
            grupo_sugerencias: "Escritura pública",
            has_vigencia: false,
            id_area_agrupacion: 31,
            id_tipo_certificado: 59,
            id_tipo_registro_FK: 20,
            precio: 10000,
            precio_recalculado: 0,
            solo_anexo: false,
            solo_valija_electronica: true,
            tipo_certificado: "Copia Escritura",
            tipo_formulario: 0,
            tipo_registro: "Escrituras Públicas"
          }
          copiaEscritura.precio = this.escritura.precio * this.escritura.numero_copias
          sug.push({
            ...copiaEscritura,
            indice: this.escritura.indice,
            numero_copias: this.escritura.numero_copias
          })
        }*/

        prohibiciones.map(p => {
          if (p.numero_copias > 0) {

            let copiaProhibiciones = {
              id_tipo_certificado:this.certificadoProhibiciones.id_tipo_certificado,
              tipo_certificado:this.certificadoProhibiciones.tipo_certificado,
              precio:this.certificadoProhibiciones.precio,
              precio_recalculado:this.certificadoProhibiciones.precio_recalculado,
              dias_entrega:this.certificadoProhibiciones.dias_entrega,
              id_tipo_registro_FK:this.certificadoProhibiciones.id_tipo_registro_FK,
              tipo_registro:this.certificadoProhibiciones.tipo_registro,
              solo_valija_electronica:this.certificadoProhibiciones.solo_valija_electronica,
              id_grupo_sugerencias:this.certificadoProhibiciones.id_grupo_sugerencias,
              grupo_sugerencias:this.certificadoProhibiciones.grupo_sugerencias,
              id_area_agrupacion:this.certificadoProhibiciones.id_area_agrupacion,
              area_agrupacion:this.certificadoProhibiciones.area_agrupacion,
              solo_anexo:this.certificadoProhibiciones.solo_anexo,
              has_vigencia:this.certificadoProhibiciones.has_vigencia,
              tipo_formulario:this.certificadoProhibiciones.tipo_formulario,
              numero_copias: null,
              vigencia: -1,
              notas:this.certificadoProhibiciones.notas,
              precio_carillas:0,
              id_tipo_tramite:1,
              id_tipo_certificado_FK:this.certificadoProhibiciones.id_tipo_certificado,
              indice: null
            }

            copiaProhibiciones.precio = p.precio_total
            sug.push({
              ...copiaProhibiciones,
              indice: p.foja_prohibicion + p.acompanamiento_foja + "-" + p.numero_prohibicion + p.acompanamiento + "-" + p.anho_prohibicion,
              numero_copias: p.numero_copias
            })
          }
        })

        alzamientos.map(a => {
          if (a.numero_copias > 0) {
            let copiaAlzamientoServiu = {
              id_tipo_certificado:this.alzamientoServiu.id_tipo_certificado,
              tipo_certificado:this.alzamientoServiu.tipo_certificado,
              precio:this.alzamientoServiu.precio,
              precio_recalculado:this.alzamientoServiu.precio_recalculado,
              dias_entrega:this.alzamientoServiu.dias_entrega,
              id_tipo_registro_FK:this.alzamientoServiu.id_tipo_registro_FK,
              tipo_registro:this.alzamientoServiu.tipo_registro,
              solo_valija_electronica:this.alzamientoServiu.solo_valija_electronica,
              id_grupo_sugerencias:this.alzamientoServiu.id_grupo_sugerencias,
              grupo_sugerencias:this.alzamientoServiu.grupo_sugerencias,
              id_area_agrupacion:this.alzamientoServiu.id_area_agrupacion,
              area_agrupacion:this.alzamientoServiu.area_agrupacion,
              solo_anexo:this.alzamientoServiu.solo_anexo,
              has_vigencia:this.alzamientoServiu.has_vigencia,
              tipo_formulario:this.alzamientoServiu.tipo_formulario,
              numero_copias: null,
              vigencia: -1,
              notas:this.alzamientoServiu.notas,
              precio_carillas:0,
              id_tipo_tramite:1,
              id_tipo_certificado_FK:this.alzamientoServiu.id_tipo_certificado,
              indice: null
            }
            copiaAlzamientoServiu.precio = a.precio_total
            items_alz.push({
              ...copiaAlzamientoServiu,
              indice: "No aplica",
              numero_copias: a.numero_copias
            })
            this.$set(this.dataFormulario, "id_tipo_formulario", ID_TIPO_FORMULARIO.FNA)
            this.$set(this.dataFormulario, "datos_adicionales", [{
              nombre: "titulo",
              valor: this.dataFormulario.foja + "-" + this.dataFormulario.numero + "-" + this.dataFormulario.anho,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            }, {
              nombre: "prohibicion",
              valor: a.indice,
              id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
              id_area_agrupacion: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO
            }

            ])
          }
        })

      }
      if (this.certificacionDeVigencia == true) {
        this.dataFormulario.datos_adicionales.push({
          nombre: "vigencia",
          valor: 'SI',
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        })
      }
      let data2 = null;
      if (items_alz.length > 0) {
        data2 = {
          ...this.dataFormulario,
          id_tipo_formulario: ID_TIPO_FORMULARIO.ALZAMIENTO_PLAZO_VENCIDO
        }
        this.dataFormulario.datos_adicionales = null;
      }
      this.documentosAgregados = true;

      //aqui se cambia el tipo de certificado si es que hay un comunero.
      for (let i = 0; i < sug.length; i++) {
        //se agrega campo notas para saber el nombre del comunero
        sug[i].notas = ""
        if (sug[i].id_tipo_certificado === registros.ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE && this.mostrarMensajeComunidad) {
          sug[i] = {...this.cerDomVigente}
          sug[i].numero_copias = 1
          sug[i].vigencia = -1
          sug[i].notas = "Nombre del Comunero: " + this.nombreComunero + ". Número del comunero: "+ this.numeroComunero
        }
      }
      console.log("sug", sug, "dataformulario", this.dataFormulario, this.precio_adicional_carillas)
      carro.agregarAlCarro(sug, this.dataFormulario, this.precio_adicional_carillas).then(response => {
        if (items_alz.length > 0) {
          carro.agregarAlCarro(items_alz, data2, this.precio_adicional_carillas).then(r2 => {
            this.verModalAgregarAlzamiento = false;
            this.cerrarModalSerie();
            this.modalAgregar.documentos_repetidos = [
              ...response.documentos_repetidos,
              ...r2.documentos_repetidos
            ];
            this.modalAgregar.limite_carro = [
              ...response.limite_carro,
              ...r2.limite_carro
            ];
            this.modalAgregar.lista_agregados = [
              ...response.lista_agregados,
              ...r2.lista_agregados]
            this.modalAgregar.verModalDocumentoAgregado = true;
          })
        } else {
          this.verModalAgregarAlzamiento = false;
          this.cerrarModalSerie();
          this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
          this.modalAgregar.limite_carro = response.limite_carro
          this.modalAgregar.lista_agregados = response.lista_agregados;
          this.modalAgregar.verModalDocumentoAgregado = true;
        }
      })

    },
    cargarYValidar(data) {
      this.buscarPorNombre = false;
      setTimeout(() => {
        this.puedeValidar(data);

        this.validarRef(data)
      }, 20)

    },

    puedeValidar(data) {
      this.puedeValidarIndice = data != null;
      this.dataFormulario = data;
    },

    setFormulario(data) {
      this.mostrarDocumentosTentativos = data != null;
      this.dataFormulario = data;
    },
    aumentarCantidadDocumentos(certificado) {

      if (certificado.id_tipo_registro_FK == registros.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION && certificado.id_tipo_certificado == registros.ID_TIPO_CERTIFICADO.ALZAMIENTO_GP_UNA_PRP) {
        this.verModalGP = true;
      }
      console.log("aumentando cant doc? ", certificado)
      //doc de lo hecho por chris
      //se pregunta si el certificado aumentado es copia con vigencia o dom vigente, y si posee una comunidad para mostrar el modal de ingreso de nombre
      if (certificado.id_tipo_certificado == registros.ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE && this.nombreComunidad.length > 0) {
        this.verModalIngresoComunero = true;
        //limitante para que no pueda tener mas de 1 copia.
        if (certificado.numero_copias < 1) {
          certificado.numero_copias++
        }
        if (certificado.numero_copias > 0) {
          this.mostrarMensajeComunidad = true
        } else {
          this.mostrarMensajeComunidad = false
        }
        // acaba.
      } else if (certificado.id_tipo_certificado == registros.ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS && this.mostrarEscrituraPublica) {

        console.log('esto es escritura publica');
        this.itemDatosAdicionalesParaModalConfirmar = certificado
        this.verModalConfirmarDatosAdicionalesEscrituraPublica = true

      } else if (certificado.id_tipo_certificado == registros.ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS && !this.mostrarEscrituraPublica){
        this.verModalNoPuedeAgregarAlCarro = true
      } else if (!certificado.has_vigencia) {
        if (certificado.solo_valija_electronica) {
          if (certificado.numero_copias < 1) {
            certificado.numero_copias++;
          }
        } else {
          certificado.numero_copias++;
        }
      } else {
        this.certificadoModalAgregarVigencia = certificado;
        this.verModalAgregarVigencia = true;
      }
    },
    aumentarCantidad(certificado){
      certificado.numero_copias = 1

      certificado.datos_adicionales = [
        {
          nombre: "notaria",
          valor: certificado.notario,
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        },
        {
          nombre: "repertorio",
          valor: escrituraEntity.repertorioByFechaEscritura(certificado.numero_repertorio, certificado.fecha_escritura),
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        },
        {
          nombre: "fecha",
          valor: certificado.fecha_escritura,
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        },
        {
          nombre: "contratantes",
          valor: typeof certificado.contratantes == 'string' ? certificado.contratantes : escrituraEntity.concatenarContratantes(certificado.contratantes),
          id_tipo_formulario: ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        }
      ]
      this.itemDatosAdicionalesParaModalConfirmar = {}
    },
    aumentarCantidadDocumentosVigencia(certificado, vigencia) {

      certificado.vigencia = vigencia.anhos;
      if (certificado.solo_valija_electronica) {
        if (certificado.numero_copias < 1) {

          certificado.numero_copias++;

        }
      } else {
        certificado.numero_copias++;
      }
      this.verModalAgregarVigencia = false;
    },
    reducirCantidadDocumentos(certificado) {
      console.log("certificado: ", certificado);
      console.log("micertificado: ", this.certificado)
      if (certificado.id_tipo_certificado === this.certificado.id_tipo_certificado) {
        if (certificado.numero_copias > 1) {
          certificado.numero_copias--;
        }
      } else {
        if (certificado.numero_copias > 0) {
          certificado.numero_copias--;

          if (certificado.numero_copias == 0) {
            certificado.vigencia = -1;
          }

        }
      }
      if (certificado.id_tipo_certificado === registros.ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE && certificado.numero_copias == 0) {
        this.mostrarMensajeComunidad = false
      }

    },

    // modificarLitigio(certificado){
    //
    // },
    validarSerie() {

      if (this.dataFormulario == null) return;
      if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION) {
        this.verModalAgregarAlzamiento = true;
        return;
      }

      if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS) {
        this.modalConfirmaEscrituraPublica = true;
        return;
      }
      if (this.certificado.id_tipo_registro_FK === registros.ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {
        if (this.dataFormulario.id_tipo_formulario == ID_TIPO_FORMULARIO.PLANOS) {
          registros.isSerie(this.dataFormulario.numero, this.dataFormulario.anho, this.certificado.id_tipo_registro_FK, this.certificado.id_tipo_certificado).then(response => {
            if (response) {
              this.serie = response;
              this.serie.fojaBuscada = this.dataFormulario.foja;
              // registros.agregarAlCarro(this.sugerencias)
              this.agregarAlCarro()
            } else {
              this.serie = null;
              this.dialogSeriePlano = true;
            }
          })
        } else {
          this.agregarAlCarro(this.sugerencias, this.dataFormulario);
        }
      } else {
        this.agregarAlCarro(this.sugerencias, this.dataFormulario);
      }
    },
    cerrarModalSerie() {
      this.dialogSerie = false;
    }
  },

  computed: {
    /**
     * Determina si el certificado seleccionado debe hacer cargas hacia SCBR
     * tambien sirve para motrar el componente PanelItemsTitulo
     * @returns {boolean}
     */
    isItemConScbr() {
      if (this.certificado != null) {
        if (this.certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {
          let items = this.certificadosConScbr.filter(f => {
            return f == this.certificado.id_tipo_certificado
          })
          return items.length > 0;
        }
      }
      return false
    },
    getPrecioTotal() {
      let precioTotal = 0;
      this.sugerencias.map(cert => {
        precioTotal += cert.numero_copias * (parseInt(cert.precio) + (cert.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precio_adicional_carillas) : 0))
      })
      return precioTotal + this.precio_copias_hipotecas + this.precio_alzamientos + this.precio_copias_prohibiciones;
    },
    getSubtotal() {
      console.log("se llama")

      console.log("getPrecioTotal: " + this.getPrecioTotal)

      return this.getPrecioTotal
    },
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    }
  },

  created() {

    store.state.carro.map(itemCarro => {
      if (itemCarro.id_tipo_certificado_FK === registros.ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS){
          this.mostrarEscrituraPublica = false
      }
    })
    registros.getFromTiposRegistrosTiposCertificados(1, 3).then(res => {
      console.log("res", res)
      this.cerDomVigente = res
    })

    let categoria = this.$route.params.categoriaRegistro;
    switch (categoria) {
      case "propiedad":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde se encuentra ubicada la propiedad";
        this.mensajes.jurisdiccion_ok = "La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera."
        this.mensajes.otra_jurisdiccion = "La propiedad se encuentra en otra comuna."
        break;
      case "comercio":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de La Serena."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "aguas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de La Serena."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "minas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de La Serena."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
        break;
      case "escrituras-publicas":
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la escritura";
        this.mensajes.jurisdiccion_ok = "La escritura fue realizada en una notaría de la comuna de La Serena."
        this.mensajes.otra_jurisdiccion = "La escritura fue realizada en otra comuna."
        break;
      default:
        this.mensajes.titulo_jurisdiccion = "Seleccione donde fue realizada la inscripción";
        this.mensajes.jurisdiccion_ok = "La inscripción fue realizada en la comuna de La Serena."
        this.mensajes.otra_jurisdiccion = "La inscripción fue realizada en otra comuna."
    }


    this.url_tipo_registro = this.$route.params.tipoRegistro;
    this.url_tipo_certificado = this.$route.params.tipoCertificado;
    this.load.certificado = true;
    registros.getCertificadoTramitesEnLinea(this.url_tipo_registro, this.url_tipo_certificado).then(response => {
      this.certificado = response.certificado
      this.sugerencias = response.lista_certificados


      /**
       * Si es un item que lleva documentos cargados desde SCBR, carga en memoria los valores de los certificados que se rescatarán
       * Se evalúa esta condición para evitar consultas adicionales
       * La búsqueda de los documentos que se tienen que agregar se hará al momento de validar el índice digitado por el cliente
       */
      if (this.isItemConScbr) {

        registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA).then(r => {
          this.certificadoHipoteca = r
        })
        registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION).then(r => {
          this.certificadoProhibiciones = r
        })

        registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ALZAMIENTO_SERVIU_PLAZO_VENCIDO, ID_TIPO_CERTIFICADO.ALZAMIENTO_PLAZO_VENCIDO).then(r => {
          this.alzamientoServiu = r;
        })
      }

      switch (this.certificado.id_tipo_registro_FK) {
        case registros.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION:
          if (!carro.verificarTipoAlzamiento(this.certificado)) {
            //incompatibilidad de carros... volver a la pagina anterior
            this.verModalIncompatibilidadAlzamiento = true;
          }

          if (carro.obtenerAlzamiento()) {
            this.dataFormulario = carro.obtenerAlzamiento();
            this.formularioAlzamientoPrecargado = true;
          } else {
            //this.mostrarDocumentosTentativos = false;
            this.formularioAlzamientoPrecargado = false;
          }

          break;

        case registros.ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS:
          this.dataFormulario = carro.getDatosAdicionalesCertificado(ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS, ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS);

          if (this.dataFormulario) {
            this.formularioEscrituraPublicaPrecargado = true;

          } else {
            this.formularioEscrituraPublicaPrecargado = false;
          }
          break;
        default:
          //this.mostrarDocumentosTentativos = true;

      }
    }).catch(() => {
      this.certificado = {
        data: "Certificado no encontrado"
      }
    }).finally(() => this.load.certificado = false)
  }
  ,

}
</script>

<style scoped>
.remarcado-jurisdiccion {
  transition: background-color 0.4s linear 0.2s;
  background-color: #00acc130;
  border-radius: 7px;
}
</style>
