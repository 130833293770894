<template>
  <div class="d-flex pr-0 mr-0">
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on">
            <v-icon
                color="primary"

            >
              mdi-information-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Corresponde a un(a) {{tipo}} asociado(a) <br> al título {{dataFormulario.foja}}-{{dataFormulario.numero}}-{{dataFormulario.anho}}</span>
      </v-tooltip>
    </div>
    <div class=" mb-0 pl-2 pr-0" style="line-height: 19px">

      <span>{{ tipo_certificado }}</span>
      <br>
      <div v-if="info != null">
        <small class="d-flex info--text">
          {{info}}
        </small>
      </div>
      <div v-if="indice != null">
        <small class="d-flex">{{ indice }}
          <div class="pl-1" v-if="tipo != null">
            | {{ tipo }}
          </div>
        </small>
      </div>

      <div v-if="indice == null">
        <small class="d-flex">{{ foja }}{{
            acompanamiento_foja
          }}-{{ numero }}{{ acompanamiento }}-{{ anho }}
          <div class="pl-1" v-if="tipo != null" style="color:#2196f3">
            | {{ tipo }}
          </div>
        </small>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "DescripcionItemScbr",
  props: {
    dataFormulario: {
      default: null
    },
    foja: null,
    numero: null,
    anho: null,
    indice: null,
    tipo: null,
    acompanamiento: {default: ""},
    acompanamiento_foja: {default: ""},
    tipo_certificado: null,
    info: null
  }
}
</script>

<style scoped>

</style>
