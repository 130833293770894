<template>
  <div>
    <TheBreadcrumb class="mb-5"/>
    <v-fade-transition>
      <div v-if="!cargandoCategorias">
        <v-row
            no-gutters
            v-for="tipo_registro in lista_registros"
            :key="tipo_registro.id_tipo_registro"
            class="mt-0 mb-5"
        >

          <v-col cols="12">
            <v-alert
                class="ma-0"
                text
                style="color: rgb(33, 150, 243);"
                icon="mdi-file"
            >
              <span v-if="tipo_registro.tipo_registro === 'Propiedad'">
                Copias y certificados de
              </span>{{ tipo_registro.tipo_registro }}
            </v-alert>
          </v-col>

          <v-col>
            <v-row>
              <v-col
                  cols="12"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"

                  v-for="certificado in hideSoloAnexo(tipo_registro.tipos_certificados)"
                  :key="certificado.id_tipo_certificado"
              >
                <card-tipo-registro
                    :CertificadoProp="certificado"
                    :TipoRegistro="tipo_registro"

                />
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>


<script>
// import CardOpcionMenu from "@/components/CardOpcionMenu/CardOpcionMenu.vue";
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import CardTipoRegistro from "@/components/SolicitudDocumentos/CardTipoRegistro";
import registro, {
  ID_AGRUPACION_DOCUMENTO,
  ID_AREA_AGRUPACION,
  ID_CATEGORIA_REGISTRO, ID_TIPO_CERTIFICADO,
  ID_TIPO_REGISTRO
} from "@/model/registros/registros.entity";

export default {
  components: {CardTipoRegistro, TheBreadcrumb},
  data() {
    return {
      cargandoCategorias: false,
      url_categoria: null,
      lista_registros: null,
    };
  },

  methods: {
    hideSoloAnexo(lista_certificados) {
      return lista_certificados.filter(f => {
        return !f.solo_anexo
      })
    }
  },


  mounted() {
    this.url_categoria = this.$route.params.categoriaRegistro;

    this.cargandoCategorias = true;

    registro.getTiposRegistrosFromCategoria(this.url_categoria)
        .then((response) => {
          console.log(response)
          this.lista_registros = response;
          let obj;
          switch (this.url_categoria) {
            case "comercio":
              console.log("Es comercio")
              obj = {
                id_categoria_registro_FK: ID_CATEGORIA_REGISTRO.COMERCIO,
                id_tipo_registro: ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO,
                mostrar: false,
                posicion: 0,
                tipo_registro: "Inscripción de Comercio",
                tipos_certificados: [
                  {
                    area_agrupacion: "Constitución de Sociedad",
                    dias_entrega: 5,
                    has_vigencia: false,
                    id_area_agrupacion: ID_AREA_AGRUPACION.CONSTITUCION_DE_SOCIEDAD,
                    id_grupo_sugerencias: ID_AGRUPACION_DOCUMENTO.INSCRIPCIONES,
                    id_tipo_certificado: ID_TIPO_CERTIFICADO.INSCRIPCION_DE_COMERCIO,
                    id_tipo_registro_FK: ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO,
                    informacionCertificado: {
                      resumen: "Inscrición de comercio"
                    },
                    precio: 0,
                    solo_valija_electronica: true,
                    tipo_certificado: "Constitución de Sociedad",
                    tipo_registro: "Inscripción de Comercio",
                    url_tipo_certificado: "",
                    link: "/inscripciones/constitucion-de-sociedad"
                  },
                  {
                    area_agrupacion: "Modificación de Sociedad",
                    dias_entrega: 5,
                    has_vigencia: false,
                    id_area_agrupacion: ID_AREA_AGRUPACION.MODIFICACION_SOCIEDAD,
                    id_grupo_sugerencias: ID_AGRUPACION_DOCUMENTO.INSCRIPCIONES,
                    id_tipo_certificado: ID_TIPO_CERTIFICADO.MODIFICACION_DE_SOCIEDAD,
                    id_tipo_registro_FK: ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO,
                    informacionCertificado: {
                      resumen: "Modificación de sociedad"
                    },
                    precio: 0,
                    solo_valija_electronica: true,
                    tipo_certificado: "Modificación de Sociedad",
                    tipo_registro: "Inscripción de Comercio",
                    url_tipo_certificado: "modificacion-sociedad",
                    link: "/inscripciones/modificacion-de-sociedad"
                  }
                ]
              }

              this.lista_registros.push(obj)
              break;
          }
          console.log(obj)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.cargandoCategorias = false;
        })
  },
};
</script>
