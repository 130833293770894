<template>
  <v-row>

    <v-col cols="7" class="pb-0">
      <small class="font-weight-bold">Notario titular</small>
      <v-alert
          border="left"
          color="black"
          dense
          :icon="false"
          outlined
          class="text-center py-1"
      >
        <span style="color:black; font-size: 13px">
              {{ data.notario.nombre }}
        </span>

      </v-alert>
    </v-col>
    <v-col cols="5" class="pb-0">
      <small class="font-weight-bold">Código de verificación</small>
      <v-alert
          border="left"
          color="black"
          dense
          :icon="false"
          outlined
          class="text-center py-1"
      >
        <span style="color:black; font-size: 13px">
          {{ data.idDocumento }}
        </span>

      </v-alert>
    </v-col>
    <v-col cols="5" class="pt-0">
      <small class="font-weight-bold">Repertorio</small>
      <v-alert
          border="left"
          color="black"
          dense
          :icon="false"
          outlined
          class="text-center py-1"
      >
        <span style="color:black; font-size: 13px">
              {{ data.repertorio }}
        </span>

      </v-alert>
    </v-col>
    <v-col cols="7" class="pt-0">
      <small class="font-weight-bold">Partes</small>
      <v-alert
          border="left"
          color="black"
          dense
          :icon="false"
          outlined
          class="text-center py-1"
      >
        <span style="color:black; font-size: 13px">
              {{ data.partes }}
        </span>

      </v-alert>
    </v-col>
  </v-row>


</template>

<script>
export default {
  name: "AlzamientoPrecargado",
  props: {
    data: {
      default: null
    }
  }
}
</script>

<style scoped>

</style>
