<template>
  <div>
    <v-fade-transition>

      <div v-if="!cargandoRegistros">
        <v-row>

            <v-col
                v-for="registro in categorias_registros"
                :key="registro.id_categoria_registro"
                xs="12"
                sm="6"
                md="6"
                lg="3"
                xl="3"
            >
              <router-link :to="{ name: 'CategoriasRegistros', params: {categoriaRegistro: registro.url_categoria}}">

                <div class="d-flex flex-column px-4 px-md-0">

                  <v-img
                      :src="require(`@/assets/img/iconos-registros/${idToImage(registro.id_categoria_registro)}.png`)"
                      eager
                      class="oscurecer"
                  />

                  <p class="pt-4 mb-0 text-body-2 text-right descripcion-registros">
                    {{ registro.descripcion }}
                  </p>
                </div>

              </router-link>

            </v-col>


        </v-row>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import registros, {idToImage} from "@/model/registros/registros.entity";

export default {
  name: "RegistrosPrincipales",
  data() {
    return {
      cargandoRegistros: false,
      categorias_registros: [],
    };
  },
  created() {
    this.cargandoRegistros = true;
    registros.getCategoriasRegistros().then((response) => {
      this.categorias_registros = response.filter((reg)=>{
        if(reg.id_categoria_registro <= 4)
        {
          return reg;
        }
      })
      //this.categorias_registros = response;
    }).finally(() => {
      this.cargandoRegistros = false;
    });
  },
  methods: {
    idToImage: idToImage
  }
};
</script>

<style scoped>
.descripcion-registros {
  color: #088397 !important;
}

a {
  text-decoration-line: none !important;
}

.oscurecer:hover{
  transition-duration: 500ms;
  transition-property: filter;
  filter:brightness(0.8)
}
</style>