<template>
  <div class="d-flex">
    <v-btn class="px-0" style="min-width: 25px"
           small
           elevation="0"
           tile
           outlined
           icon
           height="26"
           color="primary"
           @click="reducir()"
    >
      <v-icon small dark>
        mdi-minus
      </v-icon>
    </v-btn>
    <input type="text"
           class="input-spin"
           disabled
           v-model="item.numero_copias"
    >
    <v-btn
        outlined
        rounded
        class="px-0" style="min-width: 25px"
        small
        elevation="0"
        tile
        color="primary"
        height="26"
        @click="aumentar()"
    >
      <v-icon small dark>
        mdi-plus
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SpinnerCantidad",
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    aumentar() {
      this.$emit("aumentar", )
    },
    reducir() {
      if (this.item.numero_copias > 0) {
        this.$emit("reducir", 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
