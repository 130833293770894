<template>
  <v-dialog max-width="450" v-model="ver" persistent>
    <v-card>
      <v-alert
          border="left"
          colored-border
          :type="tipo"
          class="mb-0 px-8"
          :icon="false"
      >

        <p class="text-center font-weight-bold info--text">Hemos detectado que los datos registrales ingresados
          corresponden a una comunidad agrícola. Por favor, ingrese el nombre  y el numero del comunero.</p>
        <v-form @submit.prevent="guardarNombreComunero()">
          <v-text-field
              outlined
              label="Nombre"
              autofocus
              dense
              :rules="[lettersOnlyRule]"
              @input="nombreComunero = nombreComunero.toUpperCase()"
              v-model="nombreComunero"
          ></v-text-field>
          <v-text-field
              outlined
              type="number"
              label="Número"
              dense
              v-model="numeroComunero"
          ></v-text-field>
          <v-row>
            <v-col cols="12" md="12" class="text-center pt-0">
              <v-btn type="submit" :disabled="!btnDissabled || numeroComunero === null || numeroComunero === ''" color="info">
                Ingresar nombre y numero
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <slot/>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalIngresoNombreComunero",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false,
      nombreComunero: '',
      btnDissabled: false,
      numeroComunero: null,
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    lettersOnlyRule(value) {
      const pattern = /^[a-zA-ZÀ-ÿ]+([ ][a-zA-ZÀ-ÿ]+)*$/
      if (!pattern.test(value)) {
        this.btnDissabled = false
        return 'Solo se permiten letras'
      }
      this.btnDissabled = true
      return true
    },
    guardarNombreComunero() {
      const comunero = {
        nombre: this.nombreComunero,
        numero: this.numeroComunero
      }
      this.$emit("e-nombreComunero", comunero)
      this.nombreComunero = ""
      this.numeroComunero = null
      this.hideDialog()
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
  }
}
</script>

<style scoped>

</style>
