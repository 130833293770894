<template>
  <v-card elevation="0">
    <v-autocomplete
        dense
        outlined
        :loading="cargandoNotariasSii"
        item-text="notario"
        label="Notario"
        return-object
        hide-details
        :items="notariasSii"
        v-model="notario_select"
    />
    <v-row>
      <v-col class="mb-0 pb-1" cols="6">
        <v-text-field
            placeholder="Ej: 123-2020"
            :rules="rulesRepertorio"
            outlined
            autofocus
            dense
            hide-details
            label="N° de repertorio y año"
            v-model="repertorio"
        />
      </v-col>
      <v-col cols="6" class="pl-0 mb-0 pb-0">
        <v-text-field
            placeholder="Fecha"
            :max="nowDate"
            style="padding-bottom: 1px"
            hide-details
            outlined dense label="Fecha" type="date"
            v-model="fecha"
            :error="splitDate[0]!=splitRepertorio[1] && splitDate.length>0 && splitRepertorio.length > 0 "
        ></v-text-field>
        <v-expand-transition>
          <div style="font-size: 12px;color: #9d0101 ; padding-left: 2px"
               v-show="splitDate[0]!=splitRepertorio[1] && splitDate.length>0 && splitRepertorio.length > 0 ">
            El año de firma de su escritura debe coincidir con el año ingresado en el repertorio
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-text-field
        class="mt-2"
        id="contratantes" placeholder="Ej: Contratante 1, Contratante 2..."
        dense outlined
        hide-details
        label="Contratantes"
        v-model="contratantes"></v-text-field>
    <span
        @click="$emit('ejecutarVolver')"
        style="font-size: 12px; cursor: pointer"
        class="info--text mb-0"
    >
      <span> Volver </span>
      <v-icon small pl-2 color="info">mdi-undo-variant</v-icon>
    </span>

    <v-alert v-if="fechasEscriturasBusqueda.length > 0 && !isFechaAcertada" class="text-center" style="margin-top: 6px" color="info"
             outlined dense>
      Según nuestros registros, para el notario y repertorio mencionado la fecha de escritura sugerida sería:
      <div class="text-center pb-4" v-for="(item, index) in eliminarRepetidos(fechasEscriturasBusqueda)"
           :key="item+index">
        <div>{{ item | toDate }}</div>
      </div>
      Puede corregir su fecha de escritura o proceder con los datos ya tipeados.
    </v-alert>
    <v-card-text class="text-center pt-0 mt-2">
        <v-btn
            @click="validarFechasEscrituras()"
            :disabled="(fecha=='' || contratantes=='' || repertorio=='')|| splitDate[0]!=splitRepertorio[1]"
            color="success"
            :loading="btnLoader">
          Confirmar datos de escritura
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import notariasSiiEntity from "@/model/notarias-sii/notarias-sii.entity";
import {escrituraEntity} from "@/model/escritura/escritura.entity";
import {toDateFilter} from "@/filters/toDateFilter";

export default {
  name: "CardingresoDatosEscrituras",
  data() {
    return {
      notario_select: {notario: 'Oscar Fernandez Mora'},
      notariasSii: [],
      cargandoNotariasSii: false,
      rulesRepertorio: [
        value => (value && /^\d+-\d+$/.test(value)) || 'El formato debe ser numero-año',],
      repertorio: "",
      nowDate: new Date().toISOString().slice(0, 10),
      fecha: "",
      contratantes: "",
      fechasEscriturasBusqueda: [],
      splitDate: [],
      splitRepertorio: [],
      fechaFormatoCorrecto: '',
      isFechaAcertada:false,
      btnLoader:false,
    }
  },
  watch: {
    fecha() {
      console.log('hola split date? ', this.fecha.split('-'))
      this.splitDate = this.fecha.split('-')
      this.fechaFormatoCorrecto = this.splitDate[2]+'/'+this.splitDate[1]+'/'+this.splitDate[0]
    },
    repertorio() {
      console.log('hola split date? ', this.repertorio.split('-'))
      this.splitRepertorio = this.repertorio.split('-')
    }
  },
  mounted() {
    this.traerNotarias()
  },
  methods: {
    eliminarRepetidos(array) {
      const dataArr = new Set(array);
      let result = [...dataArr];
      // buscamos si existe una fecha que coincida con la ingresada.
      result.every( r =>{
        if(toDateFilter(r) == this.fechaFormatoCorrecto){
          this.isFechaAcertada = true
          // con un return false se deja de iterar.
          return false
        }else{
          this.isFechaAcertada = false
          return true;
        }
      })
      return result
    },
    validarFechasEscrituras() {
      this.btnLoader = true;
      let splitTextFielRepertorio = this.repertorio.split('-')
      let numeroRepertorio = splitTextFielRepertorio[0]
      let anhoRepertorio = splitTextFielRepertorio[1]
      // esta funcion recibe las fechas de las escrituras publicas, si encuentra una coincidencia cambia el valor de enviarItem
      // para poder cambiar el valor de cotiza a 20.000 o alrevez.
      // si llega true, se settea (por documentar).
      escrituraEntity.validarFechasEscrituras(this.notario_select.id_notaria_sii, numeroRepertorio, anhoRepertorio).then(res => {
        this.btnLoader = false;
        if (res.data.status) {
          console.log('respuesta al validar fechas de escrituras', res.data.obj)
          let objEnviar = {
            notario: this.notario_select.notario,
            numero_repertorio: this.splitRepertorio[0],
            fecha_escritura: this.fechaToMillis(this.fecha),
            enviarItem: true,
            contratantes: [[], []]
          }
          if(this.contratantes.length>0){
            objEnviar.contratantes = this.contratantes
          }
          this.contratantes.split('y')
          if (res.data.obj.length != 0) {
            this.fechasEscriturasBusqueda = res.data.obj
            objEnviar.enviarItem = false
            this.eliminarRepetidos(this.fechasEscriturasBusqueda).forEach(fecha => {
              if (this.convertirTiempoAFecha(fecha) == this.fecha) {
                this.$emit('enviarItem', objEnviar)
                throw 'detener el ciclo'
              } else {
                objEnviar.enviarItem = true
                this.$emit('enviarItem', objEnviar)
              }
            })
          } else {
            this.fechasEscriturasBusqueda = []
            objEnviar.enviarItem = true
            this.$emit('enviarItem', objEnviar)
          }
        }
      })
    },
    convertirTiempoAFecha(tiempoEnMilisegundos) {
      const fecha = new Date(tiempoEnMilisegundos);
      const year = fecha.getFullYear();
      const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const day = fecha.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    fechaToMillis(fecha) {
      // Separamos la fecha en año, mes y día
      const [anio, mes, dia] = fecha.split('-');
      // Creamos un objeto Date con la fecha
      const fechaObj = new Date(anio, mes - 1, dia);
      // Devolvemos los milisegundos desde la época Unix
      return fechaObj.getTime();
    },
    cotizarBtn() {
      console.log({
        notario: this.notario_select.notario,
        numero_repertorio: this.repertorio,
        fecha_escritura: this.fecha,
      })
      this.$emit('enviarItem', {
        notario: this.notario_select.notario,
        numero_repertorio: this.repertorio,
        fecha_escritura: this.fechaToMillis(this.fecha),
        enviarItem: true,
        contratantes: [[], []]
      })
    },
    traerNotarias() {
      this.cargandoNotariasSii = true
      notariasSiiEntity.obtenerNotariasSii().then(res => {
        console.log("res notaria sii ", res)
        this.cargandoNotariasSii = false
        this.notariasSii = res.data.obj
        this.notario_select = this.notariasSii[0]
      })
    },
  }
}
</script>

<style scoped>

</style>